var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", size: "mini", data: _vm.datas }
        },
        [
          _c("el-table-column", {
            attrs: {
              fixed: "left",
              prop: "Member.Code",
              label: "客户编号",
              width: "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "Member.Name",
              fixed: "left",
              label: "姓名",
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "Member.Mobile", label: "手机号", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "Member.Sex", label: "性别", width: "60" }
          }),
          _c("el-table-column", {
            attrs: { prop: "Member.Birthday", label: "年龄", width: "60" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.format.getAgeByDateString(
                            _vm.format.dateFormat(scope.row.Member.Birthday)
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "HipLeftScore", label: "左髋疼痛" }
          }),
          _c("el-table-column", {
            attrs: { prop: "HipRightScore", label: "右髋疼痛" }
          }),
          _c("el-table-column", {
            attrs: { prop: "KneeLeftScore", label: "左膝疼痛" }
          }),
          _c("el-table-column", {
            attrs: { prop: "KneeRightScore", label: "右膝疼痛" }
          }),
          _c("el-table-column", {
            attrs: { prop: "AnkleLeftScore", label: "左踝疼痛" }
          }),
          _c("el-table-column", {
            attrs: { prop: "AnkleRightScore", label: "左踝疼痛" }
          }),
          _c("el-table-column", {
            attrs: { prop: "InsertTime", label: "自测时间", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.format.dateTimeFormat(scope.row.InsertTime)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        staticClass: "pagination",
        attrs: {
          background: "",
          "page-sizes": _vm.pageSizeList,
          "page-size": _vm.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total,
          "current-page": _vm.pageIndex
        },
        on: {
          "size-change": _vm.pageSizeChange,
          "current-change": _vm.pageCurrentChange,
          "update:currentPage": function($event) {
            _vm.pageIndex = $event
          },
          "update:current-page": function($event) {
            _vm.pageIndex = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }